import { useMemo, useReducer, useState } from "react";
import deepmerge from "deepmerge";
import {
  Any,
  SectionMap,
  CounterData,
  SectionData,
  TestState,
  CounterReducer,
  CounterReducerState,
  CounterState,
  RecursivePartial,
  Localized,
} from "types";
import { findFirstSection, clone, counterReducer, getCurrentSectionAndPage } from "./counterReducer";

const createInitialState = <Data extends CounterData, Id extends string>(
  initialData: Data,
  sections: SectionData<Data, Id>[]
) => {
  const sectionMap: SectionMap<Data, Id> = sections.reduce((sMap, s) => ({ ...sMap, [s.sectionId]: s }), {} as Any);
  const firstSectionId = findFirstSection(sectionMap);

  const initialState: CounterReducerState<Data, Id> = {
    _initialData: initialData,
    _isTest: false,
    data: clone(initialData),
    visibleSections: [[firstSectionId]],
    sectionMap: { ...sectionMap },
  };
  return initialState;
};

// docs: https://www.npmjs.com/package/deepmerge
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const overwriteMerge = (destinationArray: Any, sourceArray: Any, options: Any) => sourceArray;

const mergeTestState = <Data extends CounterData, Id extends string>(
  initialState: CounterReducerState<Data, Id>,
  testState: TestState<Data, Id>
): CounterReducerState<Data, Id> => {
  return {
    ...initialState,
    ...testState,
    data: deepmerge<Data, RecursivePartial<Data>>(initialState.data, testState.data || {}, {
      arrayMerge: overwriteMerge,
    }),
    _isTest: true,
  };
};

const useCounterState = <Data extends CounterData, Id extends string>(
  initialData: Data,
  sections: SectionData<Data, Id>[],
  localized?: Localized,
  testState?: TestState<Data, Id>
): CounterState<Data, Id> => {
  const initialState = useMemo(() => {
    let state = createInitialState(initialData, sections);
    if (testState) {
      state = mergeTestState(state, testState);
    }
    return state;
  }, [initialData, testState]);

  const [state, dispatch] = useReducer<CounterReducer<Data, Id>>(counterReducer, initialState);

  const setData = (data: Partial<Data>) => dispatch({ type: "setData", data });

  const { data, visibleSections, sectionMap } = state;

  const [showErrors, setShowErrors] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const value = {
    data,
    visibleSections,
    sectionMap,
    dispatch,
    setData,
    ...getCurrentSectionAndPage(state.visibleSections),
    showErrorModal,
    setShowErrorModal,
    showErrors,
    setShowErrors,
    localized,
  };
  return value;
};

export default useCounterState;
