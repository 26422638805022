export const sv = {
  datePickerInput: {
    weekNumber: "Veckonummer",
    monthDropdown: "Månad",
    yearDropdown: "År",
    nextMonth: "Gå till följande månad",
    previousMonth: "Gå till föregående månad",
    toggleCalendar: "Välj datum",
    closeCalendar: "Stäng kalendern",
    selected: "vald",
    goToCurrentDay: "Gå till dagens datum",
    placeholder: "dd.mm.åååå",
  },
} as const;
