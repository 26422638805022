import { nanoid } from "nanoid";
import { useMemo } from "react";

/**
 * Returns a valid html-id.
 * If on test-environment, it always returns "A", so it doesn't break snapshots.
 */
const genId = (): string => {
  if (process.env.NODE_ENV === "test") return "A";
  return "A" + nanoid(10); // HTML-id must start with a letter -> always start with "A"
};

const useId = (id?: string): string => {
  const elementId = useMemo(() => id || genId(), [id]);
  return elementId;
};

export default useId;
