import { Collapse, CollapseToggle, Button, CollapseBody, InputLabel } from "kela-design-system";
import CaretUp from "kela-design-system/dist/icons/ui/IconCaretUp";
import CaretDown from "kela-design-system/dist/icons/ui/IconCaretDown";
import React, { ReactElement, useState } from "react";

interface CollapseProps {
  id: string;
  isOpenHeading: string;
  content: JSX.Element;
  label?: string;
  isClosedHeading?: string;
  buttonTestId?: string;
  required?: boolean;
  requiredLabel?: string;
  util?: string;
}

const CommonCollapse = (props: CollapseProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { required, id, label, isOpenHeading, isClosedHeading, content, buttonTestId, util, requiredLabel } = props;
  const iidee = id ?? "commonCollapse";
  return (
    <Collapse isOpen={isOpen} id={iidee}>
      {label && <InputLabel required={required} children={label} requiredLabel={requiredLabel} />}
      <div>
        <CollapseToggle>
          <Button
            data-testid={buttonTestId ?? iidee + "ButtonTestId"}
            appearance="ghost"
            className={util ? util + " kds-pl-0" : "kds-mb-4 kds-mt-4 kds-pl-0"}
            iconAfter={isOpen ? <CaretUp /> : <CaretDown />}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? isOpenHeading : isClosedHeading ?? isOpenHeading}
          </Button>
        </CollapseToggle>
      </div>
      <CollapseBody>{content}</CollapseBody>
    </Collapse>
  );
};

export default CommonCollapse;
