import React, { ReactNode } from "react";
import { Heading } from "kela-design-system";

interface LaskuritHeadingProps {
  as?: any;
  children?: ReactNode;
  id?: string;
  className?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | "category";
  display?: 1 | 2 | 3 | 4;
  testId?: string;
  util?: string;
  underline?: boolean;
}

const LaskuritHeading: React.FC<LaskuritHeadingProps> = ({ children, ...props }) => {
  const width = screen.width;
  let narrow = false;
  if (width <= 576) {
    narrow = true;
  }

  if (narrow) {
    return (
      <Heading {...props} size={3} style={{ marginTop: "1rem" }}>
        {children}
      </Heading>
    );
  } else
    return (
      <Heading {...props} style={{ marginTop: "1rem" }}>
        {children}
      </Heading>
    );
};

export default LaskuritHeading;
