import { Numeric } from "types";

const setValueNumeric = (value: Numeric, fixed: boolean): string => {
  const returnValue = value?.toFixed(2).toString().replace(".", ",").split(".");
  if (returnValue && fixed) {
    returnValue[0] = returnValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return returnValue?.join(".");
  }
  return value?.toString() ?? "0";
};

export default setValueNumeric;
