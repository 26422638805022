import React, { useRef, ReactNode } from "react";
import { PropsOf } from "types";
import useScroll from "../hooks/useScroll";

interface HeadingProps extends PropsOf<"h2"> {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: ReactNode;
  focusOnCreate?: boolean;
}
const SimpleHeading: React.FC<HeadingProps> = ({ as = "h2", children, focusOnCreate = true, ...attrs }) => {
  const headingEl = useRef<HTMLHeadingElement>(null);
  useScroll(headingEl, focusOnCreate);

  const Tag = as;
  return (
    <Tag ref={headingEl} {...attrs}>
      {children}
    </Tag>
  );
};

export default SimpleHeading;
