import { Radio, SelectionGroup } from "kela-design-system";
import React, { ReactElement } from "react";
import useId from "../hooks/useId";

type Primitive = string | number | boolean | null;

export type RadioInputOption = {
  value: Primitive;
  label: string;
  infoText?: string;
};

type SelectionGroupProps = React.ComponentProps<typeof SelectionGroup>;
interface RadioInputProps<T extends Primitive> extends Omit<SelectionGroupProps, "onChange"> {
  value: T;
  options: RadioInputOption[];
  onChange?: (value: T) => void;
}

const RadioInput = <T extends Primitive>(props: RadioInputProps<T>): ReactElement => {
  const { value, labelText, helpText, options, disabled, onChange, invalid, children, id, className, ...attrs } = props;
  const elementId = useId(id);

  return (
    <SelectionGroup
      className={className ?? "kds-mb-6"}
      labelText={labelText}
      helpText={helpText}
      disabled={disabled}
      id={elementId}
      {...attrs}
      children={
        <>
          {children}
          {options.map((option, i) => {
            const optionId = `${elementId}-${option.value}-${i}`;
            return (
              <Radio
                name={id}
                id={optionId}
                key={optionId}
                labelText={option.label}
                infoText={option.infoText}
                value={option.value + ""}
                invalid={invalid}
                checked={value === option.value}
                onChange={() => onChange && onChange(option.value as T)}
                className="kds-mb-1"
              />
            );
          })}
        </>
      }
    />
  );
};

export default RadioInput;
