import React, { ReactElement, useEffect } from "react";
import { setFocusToElement } from "shared";
import { LaskuritHeading } from "shared";

type TopViewProps = {
  heading: string;
  sectionHeading: string;
  sectionPhase: string;
  children?: React.ReactNode;
  currentPageIndex: number;
};

const TopView: React.FC<TopViewProps> = (props): ReactElement => {
  const { heading, sectionHeading, sectionPhase, children, currentPageIndex } = props;
  const mainHeadingId = "main-heading";
  const phaseId = "phase";
  useInitialFocus(currentPageIndex === 0 ? mainHeadingId : phaseId);
  return (
    <>
      <LaskuritHeading as="h1" className="kds-mb-5 kds-mt-2" id={mainHeadingId}>
        {heading}
      </LaskuritHeading>
      <p id={phaseId}>{sectionPhase}</p>
      {children}
      <LaskuritHeading as="h2" className="kds-mt-4 kds-mb-6">
        {sectionHeading}
      </LaskuritHeading>
    </>
  );
};

const useInitialFocus = (elementId = "main-heading"): void => {
  useEffect(() => {
    setFocusToElement(elementId);
  }, []);
};

export default TopView;
