import { Action, AnyRecord, CounterData, CounterReducerState, SectionMap } from "types";

export const clone = <T extends AnyRecord>(value: T): T => {
  return JSON.parse(JSON.stringify(value));
};

export const getCurrentSectionAndPage = <Id extends string>(
  visibleSections: Id[][]
): { currentPageIndex: number; currentSectionId: Id } => {
  const currentPageIndex = visibleSections.length - 1;
  const currentPage = visibleSections[currentPageIndex];
  const currentSectionId = currentPage[currentPage.length - 1];
  return {
    currentPageIndex,
    currentSectionId,
  };
};

export const findFirstSection = <Data extends CounterData, Id extends string>(sectionMap: SectionMap<Data, Id>): Id => {
  return (Object.keys(sectionMap).find((id) => sectionMap[id as Id].isFirst) || "") as Id;
};

const findNextSection = <Data extends CounterData, Id extends string>(state: CounterReducerState<Data, Id>) => {
  const { visibleSections } = state;
  const { currentPageIndex, currentSectionId } = getCurrentSectionAndPage(visibleSections);

  const { findNextId } = state.sectionMap[currentSectionId];

  const nextId = typeof findNextId === "function" ? findNextId(state.data) : findNextId;
  if (!nextId) {
    throw new Error("goToNextSection was called but no next section is found");
  }

  const nextSectionData = state.sectionMap[nextId];

  let nextVisibles = [] as Id[][];
  if (nextSectionData.isNewPage) {
    nextVisibles = [...visibleSections, [nextId]];
  } else {
    nextVisibles = visibleSections.map((pageVisibles, i) =>
      i === currentPageIndex ? [...pageVisibles, nextId] : pageVisibles
    );
  }

  return {
    visibleSections: nextVisibles,
  };
};

const findPreviousSection = <Data extends CounterData, Id extends string>(state: CounterReducerState<Data, Id>) => {
  const { visibleSections, data, sectionMap } = state;
  const { currentPageIndex, currentSectionId } = getCurrentSectionAndPage(visibleSections);
  const newData = {
    ...data,
    ...resetFields(state, sectionMap[currentSectionId].dependencies || []),
  };

  if (visibleSections[currentPageIndex].length === 1) {
    // CASE: First section of first page. Do nothing.
    if (currentPageIndex === 0) {
      return {};
    }
    // CASE: On the last section of a page that isn't the first page. -> remove the page.
    return {
      visibleSections: visibleSections.slice(0, -1),
      data: newData,
    };
  }
  // Remove last section of the current (last) page
  return {
    visibleSections: visibleSections.map((pageVisibles, i) =>
      currentPageIndex === i ? pageVisibles.slice(0, -1) : pageVisibles
    ),
    data: newData,
  };
};

const resetToPreviousSection = <Data extends CounterData, Id extends string>(
  state: CounterReducerState<Data, Id>,
  sectionId: Id
) => {
  const flattened = state.visibleSections.flat();
  if (!flattened.find((id) => id === sectionId)) {
    console.error("Section has not been visited");
    return state;
  }

  let updatedState = { ...state };
  let iters = 0;

  while (getCurrentSectionAndPage(updatedState.visibleSections).currentSectionId !== sectionId) {
    iters += 1;
    if (iters === flattened.length) break;

    updatedState = {
      ...updatedState,
      ...findPreviousSection(updatedState),
    };
  }
  return updatedState;
};

const resetFields = <Data extends CounterData, Id extends string>(
  state: CounterReducerState<Data, Id>,
  fields: (keyof Data)[]
) => {
  const data: Partial<Data> = {};
  for (const key of fields) {
    data[key] = state._initialData[key];
  }
  return data;
};

export const counterReducer = <Data extends CounterData, Id extends string>(
  state: CounterReducerState<Data, Id>,
  action: Action<Data, Id>
): CounterReducerState<Data, Id> => {
  switch (action.type) {
    case "setData":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case "goToNextSection":
      return {
        ...state,
        ...findNextSection(state),
      };
    case "goToPreviousSection":
      return {
        ...state,
        ...findPreviousSection(state),
      };
    case "resetFields":
      return {
        ...state,
        data: {
          ...state.data,
          ...resetFields(state, action.fields),
        },
      };
    case "reset":
      return {
        ...state,
        data: {
          ...clone(state._initialData),
        },
        visibleSections: [[findFirstSection(state.sectionMap)]],
      };
    case "resetToSection":
      return {
        ...state,
        ...resetToPreviousSection(state, action.sectionId),
      };
    default:
      throw new Error("Action not specified");
  }
};
