import { useEffect, RefObject } from "react";

const isScrolledIntoView = <ElType extends HTMLElement>(el: ElType, percentage: number): boolean => {
  const { top, height } = el.getBoundingClientRect();
  return top >= 0 && top + height * percentage <= window.innerHeight;
};

/**
 * - Scrolls the element into view when condition-param changes.
 * - You can give an optional parameter "percentage",
 *   that skips scrolling if percentage-amount is already visible.
 */
const useScroll = <ElType extends HTMLElement>(
  elementRef: RefObject<ElType>,
  condition = true,
  percentage?: number
): React.RefObject<ElType> => {
  /* eslint-disable no-param-reassign */
  useEffect(() => {
    if (condition && elementRef && elementRef.current) {
      const { tabIndex } = elementRef.current;
      elementRef.current.tabIndex = -1;
      elementRef.current.focus();
      elementRef.current.blur();
      elementRef.current.tabIndex = tabIndex;
      if (!percentage || !isScrolledIntoView(elementRef.current, percentage)) {
        elementRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }, [condition]);
  return elementRef;
  /* eslint-enable no-param-reassign */
};

export default useScroll;
