import React, { useRef, ReactElement, ReactNode, useState, useEffect } from "react";
import useScroll from "../hooks/useScroll";
import { CounterData, CounterState, Language } from "types";
import ErrorModal from "./ErrorModal";
import { useTimeout } from "shared";
import { i18n } from "i18next";

type SectionProps<Data extends CounterData, Id extends string> = Pick<
  CounterState<Data, Id>,
  "visibleSections" | "currentPageIndex" | "currentSectionId" | "sectionMap" | "showErrorModal" | "setShowErrorModal"
> & {
  sectionId: Id;
  i18n: i18n;
  skipFocus?: boolean;
};

type Children<Id extends string> = {
  children: ((props: { sectionId: Id }) => ReactNode) | ReactNode;
};

const Section = <Data extends CounterData, Id extends string>(
  props: SectionProps<Data, Id> & Children<Id>
): ReactElement | null => {
  const {
    children,
    visibleSections,
    currentPageIndex,
    currentSectionId,
    sectionId,
    skipFocus = false,
    showErrorModal,
    i18n,
  } = props;

  const isVisible = visibleSections[currentPageIndex].includes(sectionId);

  const [timeoutWarning, setTimeoutWarning] = useState(false);
  const [timeout, setTimeout] = useState(false);

  // 30min timeout laskureihin
  const { restart, seconds, minutes } = useTimeout({
    expiryTimestamp: getExpireryTime(),
    onExpire: () => {
      setTimeout(true), setTimeoutWarning(false);
    },
    onTreshold: () => setTimeoutWarning(true),
    tresholdSeconds: 120,
  });

  // ajastin nollaantuu kun klikkaa
  useEffect(() => {
    const resetTimer = () => restart(getExpireryTime());
    window.addEventListener("click", resetTimer);

    return () => {
      window.removeEventListener("click", resetTimer);
    };
  }, [restart]);

  const sectionRef = useRef<HTMLDivElement>(null);

  const isActive = skipFocus ? false : currentSectionId === sectionId;

  useScroll(sectionRef, isActive, 0.75);

  const language = i18n.language as Language;

  const languages: Language[] = ["fi", "sv", "en"];

  return isVisible ? (
    <div ref={sectionRef} data-testid={sectionId}>
      <ErrorModal
        //timeout pois päältä dev-tilassa
        show={showErrorModal || (process.env.NODE_ENV !== "development" && (timeout || timeoutWarning))}
        onCancel={getOnCancel({ ...props, timeoutWarning, setTimeoutWarning, setTimeout, restart })}
        language={languages.includes(language) ? language : "fi"}
        unknownUrl={false}
        timeout={timeout || timeoutWarning}
        countdownTimer={{ minutes, seconds }}
      />
      {typeof children === "function" ? children({ sectionId }) : children}
    </div>
  ) : null;
};

const getExpireryTime = () => new Date(new Date().setMinutes(new Date().getMinutes() + 30));

const getOnCancel = <Data extends CounterData, Id extends string>(
  props: SectionProps<Data, Id> & {
    timeoutWarning: boolean;
    setTimeoutWarning: React.Dispatch<React.SetStateAction<boolean>>;
    setTimeout: React.Dispatch<React.SetStateAction<boolean>>;
    restart: (newExpiryTimestamp: Date, autoStart?: boolean | undefined) => void;
  }
) => {
  const { showErrorModal, setShowErrorModal, timeoutWarning, setTimeoutWarning, setTimeout, restart } = props;
  return showErrorModal
    ? () => setShowErrorModal(false)
    : timeoutWarning
    ? () => {
        setTimeoutWarning(false), restart(getExpireryTime());
      }
    : () => {
        setTimeout(false), window.location.reload();
      };
};

export default Section;
