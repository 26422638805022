import { Modal, ModalHeader, ModalContent, ModalFooter, ButtonGroup, Button, Link } from "kela-design-system";
import React, { ReactElement, useRef } from "react";
import { TimerResult } from "react-timer-hook";
import { Language } from "types";

type CountdownTimer = Pick<TimerResult, "minutes" | "seconds">;

type TextProps = {
  language: Language;
  unknownUrl?: boolean;
  timeout?: boolean;
  countdownTimer?: CountdownTimer;
};

type ErrorModalProps = {
  show: boolean;
  onCancel?: () => void;
} & TextProps;

type RecurringTexts = {
  firstLinkText: string;
  firstHref: string;
  secondLinkText: string;
  secondHref: string;
  feedback?: string;
  feedbackHref?: string;
};

type ErrorModalTranslations = {
  heading: string;
  info?: string;
  cancelText?: string;
} & RecurringTexts;

type Texts = {
  texts: ErrorModalTranslations;
  texts2?: ErrorModalTranslations;
  texts3?: ErrorModalTranslations;
};

function getTexts(props: TextProps): Texts {
  const { language, timeout, unknownUrl } = props;
  return !unknownUrl && !timeout
    ? language === "fi"
      ? {
          texts: {
            heading: "Virhe laskurissa",
            info: "Valitettavasti laskuri ei toimi tällä hetkellä. Yritä myöhemmin uudelleen.",
            cancelText: "Palaa laskuriin",
            ...getRecurringTexts(language),
          },
        }
      : language === "sv"
      ? {
          texts: {
            heading: "Fel i räknaren",
            info: "Tyvärr fungerar räknaren inte för tillfället. Försök igen senare.",
            cancelText: "Tillbaka till räknaren",
            ...getRecurringTexts(language),
          },
        }
      : {
          texts: {
            heading: "Error in the calculator",
            info: "Unfortunately, the calculator is currently not functioning. Please try again later.",
            cancelText: "Back to the calculator",
            ...getRecurringTexts(language),
          },
        }
    : unknownUrl
    ? {
        texts: {
          heading: "Pahoittelut, laskuria ei löytynyt",
          info: "Etsimääsi laskuria ei valitettavasti löytynyt. Varmistathan, että kirjoitit osoiteen oikein.",
          ...getRecurringTexts("fi"),
        },
        texts2: {
          heading: "Tyvärr kunde räknaren inte hittas",
          info: "Räkanaren som du sökte finns inte. Kontrollera att adressen är korrekt.",
          ...getRecurringTexts("sv"),
        },
        texts3: {
          heading: "Apologies for the inconvenience. The calculator could not be found",
          info: "Unfortunately, the calculator you were looking for could not be found. Please make sure you have entered the correct address.",
          ...getRecurringTexts("en"),
        },
      }
    : getTimeoutTexts(props);
}

function getTimeoutTexts(props: TextProps): Texts {
  const { language, countdownTimer } = props;
  const { minutes, seconds } = countdownTimer ?? { minutes: 0, seconds: 0 };
  const over = !minutes && !seconds;
  const timer = () => {
    return (new Array(3).join("0") + minutes).slice(-2) + ":" + (new Array(3).join("0") + seconds).slice(-2);
  };
  return language === "fi"
    ? {
        texts: {
          heading: "Yhteys laskuriin aikakatkaistiin",
          cancelText: !over ? "Palaa laskuriin " + `(${timer()})` : "Lataa laskuri uudelleen",
          ...getRecurringTexts(language),
        },
      }
    : language === "sv"
    ? {
        texts: {
          heading: "Anslutningen till räknaren tog timeout",
          cancelText: !over ? "Tillbaka till räknaren " + `(${timer()})` : "Ladda om räknaren",
          ...getRecurringTexts(language),
        },
      }
    : {
        texts: {
          heading: "Connection to the calculator was timed out",
          cancelText: !over ? "Back to the calculator " + `(${timer()})` : "Reload the calculator",
          ...getRecurringTexts(language),
        },
      };
}

function getRecurringTexts(language: Language): RecurringTexts {
  return language === "fi"
    ? {
        feedback: "Voit antaa palautetta verkkosivuista.",
        feedbackHref: "https://www.kela.fi/anna-palautetta",
        firstLinkText: "Palaa Kela-sivustolle",
        firstHref: "https://www.kela.fi/henkiloasiakkaat",
        secondLinkText: "Palaa laskureihin",
        secondHref: "https://kela.fi/laskurit",
      }
    : language === "sv"
    ? {
        feedback: "Ge respons",
        feedbackHref: "https://www.kela.fi/ge-respons",
        firstLinkText: "Tillbaka till Fpa-webbplatsen",
        firstHref: "https://www.kela.fi/privatpersoner",
        secondLinkText: "Tillbaka till räknarna",
        secondHref: "https://www.kela.fi/berakningar",
      }
    : {
        feedback: "You can provide feedback about the website.",
        feedbackHref: "https://www.kela.fi/give-feedback",
        firstLinkText: "Back to the Kela website",
        firstHref: "https://www.kela.fi/main-page",
        secondLinkText: "Back to the calculators",
        secondHref: "https://www.kela.fi/calculators",
      };
}

const ErrorModal = (props: ErrorModalProps): ReactElement => {
  const { show, onCancel, language, unknownUrl, timeout, countdownTimer } = props;
  const { texts, texts2, texts3 } = getTexts({ language, unknownUrl, timeout, countdownTimer });
  const focusElemRef = unknownUrl ? useRef<HTMLElement>(null) : undefined;
  return (
    <Modal
      appRootId="root"
      id="errorModal"
      variant="danger"
      isOpen={show}
      closeOnClickOutside={false}
      focusRef={focusElemRef}
      readContent={!unknownUrl}
    >
      <ModalHeader ref={focusElemRef} tabIndex="-1">
        {texts.heading}
      </ModalHeader>
      {!timeout && (
        <ModalContent id="modalContent">
          <p id="modalInfo">{texts.info}</p>
          <Link id="feedbackLink" href={texts.feedbackHref}>
            {texts.feedback}
          </Link>
        </ModalContent>
      )}
      <ModalFooter id="modalFooter">
        <ButtonGroup horizontal={timeout ? undefined : "sm"}>
          <Button as={"a"} href={texts.firstHref} id="firstButton" variant="danger">
            {texts.firstLinkText}
          </Button>
          <Button as={"a"} href={texts.secondHref} id="secondButton" variant="danger">
            {texts.secondLinkText}
          </Button>
          {onCancel && (
            <Button id="thirdButton" variant="danger" appearance="outline" onClick={() => onCancel()}>
              {texts.cancelText}
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
      {unknownUrl && texts2 && (
        <>
          <ModalHeader id="modalHeader2">{texts2.heading}</ModalHeader>
          <ModalContent id="modalContent2">
            <p id="modalInfo2">{texts2.info}</p>
            <Link id="feedbackLink2" href={texts2.feedbackHref}>
              {texts2.feedback}
            </Link>
          </ModalContent>
          <ModalFooter id="modalFooter2">
            <ButtonGroup horizontal="sm">
              <Button as={"a"} href={texts2.firstHref} id="fourthButton" variant="danger">
                {texts2.firstLinkText}
              </Button>
              <Button as={"a"} href={texts2.secondHref} id="fifthButton" variant="danger">
                {texts2.secondLinkText}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </>
      )}
      {unknownUrl && texts3 && (
        <>
          <ModalHeader id="modalHeader3">{texts3.heading}</ModalHeader>
          <ModalContent id="modalContent3">
            <p id="modalInfo3">{texts3.info}</p>
            <Link id="feedbackLink3" href={texts3.feedbackHref}>
              {texts3.feedback}
            </Link>
          </ModalContent>
          <ModalFooter id="modalFooter3">
            <ButtonGroup horizontal="sm">
              <Button as={"a"} href={texts3.firstHref} id="sixthButton" variant="danger">
                {texts3.firstLinkText}
              </Button>
              <Button as={"a"} href={texts3.secondHref} id="seventhButton" variant="danger">
                {texts3.secondLinkText}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ErrorModal;
