const setFocusToElement = (elementId: string): void => {
  const elementToFocus = document.getElementById(elementId);
  if (elementToFocus) {
    elementToFocus.tabIndex = -1;
    elementToFocus.focus();
    elementToFocus.scrollIntoView({ block: "nearest", behavior: "smooth" });
  }
};

export default setFocusToElement;
