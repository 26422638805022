import { Column, TextInput } from "kela-design-system";
import React, { ReactElement } from "react";
import useId from "../hooks/useId";

type Primitive = string | number | boolean | null;
type Option = {
  value: Primitive;
  label: string;
};

const asString = (value: Primitive): string => {
  return ["undefined", "null"].includes(typeof value) ? "" : value + "";
};

type TextInputProps = React.ComponentProps<typeof TextInput>;
interface SelectInputProps<T extends Primitive>
  extends Omit<TextInputProps, "onChange" | "value" | "select" | "multiline" | "rows"> {
  value: T;
  options: Option[];
  numeric?: boolean;
  onChange?: (value: T) => void;
}

const SelectInput = <T extends Primitive>(props: SelectInputProps<T>): ReactElement => {
  const { value, labelText, options, numeric, helpText, disabled, onChange, id, inputWidth, ...attrs } = props;
  const elementId = useId(id);

  const handleChange = (value: string) => {
    if (!onChange) return;

    let val: string | number | null = value;
    if (numeric && value !== "") {
      const num = parseInt(value, 10);
      val = isNaN(num) ? null : num;
    }
    onChange(val as T);
  };

  return (
    <TextInput
      id={elementId}
      labelText={labelText}
      helpText={helpText}
      select={true}
      value={asString(value)}
      onChange={(event) => handleChange(event.target.value)}
      disabled={disabled}
      inputWidth={inputWidth || ((children) => <Column md={6}>{children}</Column>)}
      {...attrs}
    >
      {options.map((option, i) => {
        const optionId = `${elementId}-${option.value}-${i}`;
        return (
          <option id={optionId} key={optionId} value={option.value + ""}>
            {option.label}
          </option>
        );
      })}
    </TextInput>
  );
};

export default SelectInput;
