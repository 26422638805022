import React from "react";
import { useTranslation, TFunction } from "react-i18next";
import { i18n } from "i18next";

const useLocalizedContext = <D>(
  Context: React.Context<D>
): D & {
  t: TFunction<"translation", undefined>;
  i18n: i18n;
} => {
  const context = React.useContext(Context);
  const localizedContext = useTranslation();
  if (context === undefined) {
    throw new Error("useLocalizedContext not inside Context.provider");
  }
  if (localizedContext === undefined) {
    throw new Error("useLocalizedContext not inside i18next.provider");
  }
  const { t, i18n } = localizedContext;
  return { ...context, t, i18n };
};

export default useLocalizedContext;
