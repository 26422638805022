import { I18nextProvider } from "react-i18next";
import { Row, Column } from "kela-design-system";
import React, { ReactNode, ReactElement, useMemo, useEffect } from "react";
import { CounterData, CounterState, Translations } from "types";
import I18NEXT, { i18n } from "i18next";
import { formatNumber } from "helpers";

type CounterProps<Data extends CounterData, Id extends string> = {
  state: CounterState<Data, Id>;
  children: ReactNode;
  translations: Translations;
  language: string;
  debug?: boolean;
};
const Counter = <Data extends CounterData, Id extends string>(props: CounterProps<Data, Id>): ReactElement => {
  const { state, debug, translations, language, children } = props;
  const i18next = useMemo(() => createI18nInstance(translations, debug), [translations, debug]);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [i18next, language]);

  return (
    <I18nextProvider i18n={i18next}>
      <Row>
        <Column col={12}>
          {children}
          {debug && (
            <pre>{JSON.stringify({ visibleSections: state.visibleSections, data: state.data }, undefined, 2)}</pre>
          )}
        </Column>
      </Row>
    </I18nextProvider>
  );
};

const createI18nInstance = (translations: Translations, debug?: boolean): i18n => {
  const namespace = "translation";
  const resources: Translations = { fi: {} };
  Object.keys(translations).forEach((lang) => {
    resources[lang] = {
      [namespace]: translations[lang as keyof Translations],
    };
  });
  const i18next = I18NEXT.createInstance(
    {
      lng: "fi",
      fallbackLng: "fi",
      ns: [namespace],
      defaultNS: namespace,
      react: { useSuspense: false, transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"] },
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          if (format === "intlNumber") {
            return formatNumber(value);
          }
          return value;
        },
      },
      resources,
      debug,
    },
    (err) => {
      if (err) return console.log(err);
    }
  );
  return i18next;
};

export default Counter;
