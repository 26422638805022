type Links = {
  [key: string]: {
    [key: string]: string;
  };
};

const links: Links = {
  fi: {
    sairauspaivarahakausi: "https://www.kela.fi/sairauspaivaraha",
    peruspaivaraha: "https://www.kela.fi/peruspaivaraha",
    markkinatuki: "https://www.kela.fi/tyomarkkinatuki",
    vanhempainraha: "https://www.kela.fi/vanhempainpaivarahat",
    vanhempainpaivaraha: "https://www.kela.fi/vanhempainpaivarahat",
    lastenhoidontukiaika: "https://www.kela.fi/lastenhoidon-tuet",
    lastenhoidontuki: "https://www.kela.fi/lastenhoidon-tuet",
    asumistuki: "https://www.kela.fi/yleinen-asumistuki",
    elakkeensaajanasumistuki: "https://www.kela.fi/elakkeensaajan-asumistuki",
    sairauspaivaraha: "https://www.kela.fi/sairauspaivaraha",
    kuntoutusraha: "https://www.kela.fi/kuntoutusraha",
    erityishoitoraha: "https://www.kela.fi/erityishoitoraha",
    toimeentulotuki: "https://www.kela.fi/toimeentulotuki",
    opintotuki: "https://www.kela.fi/opintotuki",
    opintotukitulovalvonta: "https://www.kela.fi/opintotuki",
  },
  sv: {
    sairauspaivarahakausi: "https://www.kela.fi/sjukdagpenning",
    peruspaivaraha: "https://www.kela.fi/grunddagpenning",
    markkinatuki: "https://www.kela.fi/arbetsmarknadsstod",
    vanhempainraha: "https://www.kela.fi/foraldradagpenning",
    vanhempainpaivaraha: "https://www.kela.fi/foraldradagpenning",
    lastenhoidontukiaika: "https://www.kela.fi/barnavardsstod",
    lastenhoidontuki: "https://www.kela.fi/barnavardsstod",
    asumistuki: "https://www.kela.fi/allmant-bostadsbidrag",
    elakkeensaajanasumistuki: "https://www.kela.fi/bostadsbidrag-for-pensionstagare",
    sairauspaivaraha: "https://www.kela.fi/sjukdagpenning",
    kuntoutusraha: "https://www.kela.fi/rehabiliteringspenning",
    erityishoitoraha: "https://www.kela.fi/om-ditt-barn-insjuknar-specialvardspenning",
    toimeentulotuki: "https://www.kela.fi/utkomststod",
    opintotuki: "https://www.kela.fi/studiestod",
    opintotukitulovalvonta: "https://www.kela.fi/studiestod",
  },
  en: {
    sairauspaivarahakausi: "https://www.kela.fi/sickness-allowance",
    peruspaivaraha: "https://www.kela.fi/basic-unemployment-allowance",
    markkinatuki: "https://www.kela.fi/labour-market-subsidy",
    vanhempainraha: "https://www.kela.fi/parental-allowances",
    vanhempainpaivaraha: "https://www.kela.fi/parental-allowances",
    lastenhoidontukiaika: "https://www.kela.fi/child-care-allowances",
    lastenhoidontuki: "https://www.kela.fi/child-care-allowances",
    asumistuki: "https://www.kela.fi/general-housing-allowance",
    elakkeensaajanasumistuki: "https://www.kela.fi/housing-allowance-for-pensioners",
    sairauspaivaraha: "https://www.kela.fi/sickness-allowance",
    kuntoutusraha: "https://www.kela.fi/rehabilitation-allowance",
    erityishoitoraha: "https://www.kela.fi/om-ditt-barn-insjuknar-specialvardspenning",
    toimeentulotuki: "https://www.kela.fi/social-assistance",
    opintotuki: "https://www.kela.fi/financial-aid-for-students",
    opintotukitulovalvonta: "https://www.kela.fi/financial-aid-for-students",
  },
};

const getNavLink = (laskuri: string, lang: string): string => {
  const link = links[lang][laskuri];
  return link != undefined ? link : "https://www.kela.fi/henkiloasiakkaat";
};

export default getNavLink;
