import { Button, ButtonGroup, Modal, ModalContent, ModalFooter, ModalHeader } from "kela-design-system";
import React from "react";

type ConfirmActionModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  headingText: string;
  confirmText: string;
  cancelText: string;
  children?: React.ReactNode;
};

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  headingText,
  confirmText,
  cancelText,
  children,
}) => {
  return (
    <Modal appRootId="root" variant="danger" toggle={() => onCancel()} isOpen={isOpen} closeOnClickOutside>
      <ModalHeader>{headingText}</ModalHeader>
      {children && <ModalContent>{children}</ModalContent>}
      <ModalFooter>
        <ButtonGroup horizontal="sm">
          <Button id="varoitusJatka" variant="danger" onClick={() => onConfirm()}>
            {confirmText}
          </Button>
          <Button id="varoitusPeruuta" variant="danger" appearance="outline" onClick={() => onCancel()}>
            {cancelText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmActionModal;
