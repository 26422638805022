import { Button } from "kela-design-system";
import React from "react";
import { Counter, Section, useCounterState, useLocalizedContext } from "shared";
import { CounterState, SectionData } from "types";

const Content: React.FC = () => {
  const { setShowErrorModal } = useCounterContext();
  return (
    <Button id="toggleErrorModalButton" variant="primary" onClick={() => setShowErrorModal(true)}>
      Toggle ErrorModal
    </Button>
  );
};

const sections: SectionData<[], "Section">[] = [
  {
    isFirst: true,
    sectionId: "Section",
  },
];

type ErrorModalTestContextType = CounterState<[], "Section">;

const ErrorModalTestCounterContext = React.createContext<ErrorModalTestContextType>({} as ErrorModalTestContextType);

const useCounterContext = () => useLocalizedContext(ErrorModalTestCounterContext);

export const ErrorModalTestCounter: React.FC = () => {
  const localized = {};
  const state = useCounterState([], sections, localized);

  return (
    <Counter state={state} language={""} debug={false} translations={{ fi: [] }}>
      <ErrorModalTestCounterContext.Provider value={{ ...state }}>
        <Section {...useCounterContext()} skipFocus key={`errorModalTestSection`} {...state} sectionId={"Section"}>
          <Content />
        </Section>
      </ErrorModalTestCounterContext.Provider>
    </Counter>
  );
};
