export const fi = {
  datePickerInput: {
    weekNumber: "Viikkonumero",
    monthDropdown: "Kuukausi",
    yearDropdown: "Vuosi",
    nextMonth: "Siirry seuraavaan kuukauteen",
    previousMonth: "Siirry edelliseen kuukauteen",
    toggleCalendar: "Valitse päivämäärä",
    closeCalendar: "Sulje kalenteri",
    selected: "valittu",
    goToCurrentDay: "Siirry kuluvaan päivään",
    placeholder: "pp.kk.vvvv",
  },
} as const;
