import { Any, AnyRecord, Numeric } from "types";

/**
 * Generates all pairs from a list. If indexToCompare is specified, then
 */
export const generatePairs = <T>(list: T[], indexToCompare?: number): T[][] => {
  const pairs = [];
  let listToIterate = [...list];
  let iters = list.length - 1;

  if (indexToCompare !== undefined) {
    const removed = listToIterate.splice(indexToCompare, 1);
    listToIterate = [...removed, ...listToIterate];
    iters = 1;
  }

  for (let i = 0; i < iters; i += 1) {
    for (let j = i + 1; j < listToIterate.length; j += 1) {
      pairs.push([listToIterate[i], listToIterate[j]]);
    }
  }
  return pairs;
};

export const setValueNumeric = (value: Numeric, fixed: boolean): string => {
  const returnValue = value?.toFixed(2).toString().replace(".", ",").split(".");
  if (returnValue && fixed) {
    returnValue[0] = returnValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return returnValue?.join(".");
  }
  return value?.toString() ?? "0";
};

export const validateNumber = (min: number | null, max: number | null, value: number | null): boolean => {
  if (value === null) return false;
  if (Number.isNaN(value)) return false;
  if (max !== null && value > max) return false;
  if (min !== null && value < min) return false;
  return true;
};

export const formatNumber = (num: number | null): string => {
  if (typeof num === "number") return num.toLocaleString();
  return "NaN";
};

export const pick = <O extends AnyRecord, T extends keyof O>(obj: O, ...keys: T[]): Pick<O, T> => {
  return Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]])) as Any;
};

export const omit = <O extends AnyRecord, T extends keyof O>(obj: O, ...keys: T[]): Omit<O, T> => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key as Any))) as Any;
};

/* eslint-disable */
export const renameKeys = (obj: any, replacer: (s: any) => string, target: any = {}) => {
  for (const key of Object.keys(obj)) {
    let value;
    if (typeof obj[key] !== "object") {
      value = replacer(obj[key]);
    } else if (Array.isArray(obj[key])) {
      value = obj[key].map((m: any) => replacer(m));
    } else {
      value = renameKeys(obj[key], replacer);
    }
    target = {
      ...target,
      [key]: value,
    };
  }
  return target;
};
/* eslint-enable */

/**
 * Checks if every value in a Record is falsy
 */
export const allValuesFalsy = (record: AnyRecord) => Object.values(record).every((v) => !v);
