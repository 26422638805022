import { Heading, Skeleton } from "kela-design-system";
import React from "react";

const SimpleSkeleton: React.FC = () => {
  return (
    <div className="w-100">
      <Heading as="h1">
        <Skeleton className="w-50" />
      </Heading>
      <Skeleton variant="paragraph" />
      <Heading as="h2">
        <Skeleton className="w-25" />
      </Heading>
      <Skeleton variant="paragraph" />
      <Skeleton variant="paragraph" />
    </div>
  );
};

export default SimpleSkeleton;
