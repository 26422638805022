import { StringNull } from "types";
import { generatePairs } from "./common";

type DateRange = [string, string];

const modulo = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

export const isValidDate = (dateString: string): boolean => {
  return (
    typeof dateString === "string" &&
    dateString.length > 0 &&
    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/.test(dateString)
  );
};

/**
 * Returns date in Current year(YYYY)-Current month(MM)-01 format.
 */
export const getInitialDate = (): string => {
  const date = new Date();
  date.setDate(1);
  return toInput(date);
};

/**
 * Returns the last date of next year.
 */
export const getLastDayOfNextYear = (): string => new Date().getFullYear() + 1 + "-12-31";

/**
 * Returns date in YYYY-MM-DD format.
 */
export const toInput = (dateString: Date | string): string => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = "" + (date.getMonth() + 1);
  const day = "" + date.getDate();

  return [year, month.length < 2 ? `0${month}` : month, day.length < 2 ? `0${day}` : day].join("-");
};

/**
 * Returns date in D.M.YYYY format.
 */
export const toText = (dateString: string): string => {
  const date = new Date(dateString);
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(".");
};

/**
 * Returns date in DD.MM.YYYY format.
 */
export const toTextWithZeros = (dateString: string): string => {
  const date = new Date(dateString);
  return [
    date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate(),
    (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
    date.getFullYear(),
  ].join(".");
};

export const formatDateRange = (dateString1: string, dateString2: string): string => {
  return `${toText(dateString1)}\u2013${toText(dateString2)}`;
};

export const dateAfterYears = (startDate: string, years: number): string => {
  const date = new Date(new Date(startDate).setFullYear(new Date(startDate).getFullYear() + years));
  return toInput(date);
};

export const dateAfterMonths = (date: string, months: number): string => {
  const d = new Date(date);
  const month = d.getMonth();
  const targetMonth = modulo(month + months, 12);
  d.setMonth(d.getMonth() + months);
  while (d.getMonth() !== targetMonth) {
    d.setDate(d.getDate() - 1);
  }
  return toInput(d);
};

export const getMinDate = (dateString1: string, dateString2: string): string => {
  const d1 = new Date(dateString1);
  const d2 = new Date(dateString2);
  return d1 < d2 ? toInput(d1) : toInput(d2);
};

export const getMaxDate = (dateString1: string, dateString2: string): string => {
  const d1 = new Date(dateString1);
  const d2 = new Date(dateString2);
  return d1 > d2 ? toInput(d1) : toInput(d2);
};

/**
 * Returns the date that is offset-away from the given date.
 */
export const getOffset = (dateString: string, offset: number): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + offset);
  return toInput(date.toUTCString());
};

export const getOffsetAsText = (dateString: string, offset: number): string => {
  return toText(getOffset(dateString, offset));
};

export const isWithinRange = (min: string, max: string, dateString: string): boolean => {
  if (!isValidDate(dateString)) return false;
  const date = new Date(dateString);
  if (min && new Date(min) > date) return false;
  if (max && new Date(max) < date) return false;
  return true;
};

/**
 * Checks if two date-ranges overlap.
 */
export const hasOverlap = (dateRange1: DateRange, dateRange2: DateRange): boolean => {
  const [start1, end1] = dateRange1;
  const [start2, end2] = dateRange2;
  return new Date(start1) <= new Date(end2) && new Date(end1) >= new Date(start2);
};

/**
 * Checks if all date-ranges are disjoint. (no overlaps)
 */
export const isDisjointDateRanges = (dateRanges: DateRange[]): boolean => {
  const pairs = generatePairs(dateRanges);
  for (const [range1, range2] of pairs) {
    if (hasOverlap(range1, range2)) {
      return false;
    }
  }
  return true;
};

/**
 * Finds all overlapping date-ranges for the date-range at the given index.
 */
export const getAdjointDateRanges = (dateRanges: DateRange[], indexToCompare: number): DateRange[] => {
  const pairs = generatePairs(dateRanges, indexToCompare);
  const adjointRanges: DateRange[] = [];
  for (const [range1, range2] of pairs) {
    if (hasOverlap(range1, range2)) {
      adjointRanges.push(range2);
    }
  }
  return adjointRanges;
};

/**
 * Returns the year of a date as a number
 */
export const getYear = (dateString: string): number => Number(dateString.substring(0, 4));

/**
 * Returns ther given date in YYYY-MM-01 format
 */
export const getDateOfFirstDayOfCurrentMonth = (date: string | Date): string => {
  const d = new Date(date);
  return toInput(new Date(d.getFullYear(), d.getMonth(), 1));
};

/**
 * Returns the given date in YYYY-MM-(LAST DAY OF MONTH) format
 */
export const getDateOfLastDayOfCurrentMonth = (date: string | Date): string => {
  const d = new Date(date);
  return toInput(new Date(d.getFullYear(), d.getMonth() + 1, 0));
};

/**
 * Converts given date to locale date string
 */
export const dateToLocale = (date: StringNull): string => new Date(date as string).toLocaleDateString();
