export const en = {
  datePickerInput: {
    weekNumber: "Week number",
    monthDropdown: "Month",
    yearDropdown: "Year",
    nextMonth: "Move to the next month",
    previousMonth: "Move to the previous month",
    toggleCalendar: "Select a date",
    closeCalendar: "Close the calendar",
    selected: "selected",
    goToCurrentDay: "Go to the current day",
    placeholder: "dd.mm.yyyy",
  },
} as const;
