import { Accordion, AccordionToggle, AccordionTitle, AccordionBody } from "kela-design-system";
import React, { ReactNode, useState } from "react";

const SimpleCollapse: React.FC<{ title: string; children: ReactNode }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Accordion id={"collapse-" + title} className="kds-mb-4" isOpen={isOpen}>
      <AccordionToggle onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle>{title}</AccordionTitle>
      </AccordionToggle>
      <AccordionBody>{children}</AccordionBody>
    </Accordion>
  );
};

export default SimpleCollapse;
