import React, { ReactNode } from "react";
import { PropsOf } from "types";
import { LaskuritHeading } from "shared";

type ElementProps = Omit<PropsOf<"div">, "title">;
interface SimpleCardProps extends ElementProps {
  title: ReactNode;
  children: ReactNode;
  util?: string;
}
const SimpleCard: React.FC<SimpleCardProps> = ({ title, children, util = "", ...attrs }) => {
  const classes = `kds-p-2 kds-mt-2 kds-bg-light kds-text-dark ${util}`;
  return (
    <div className={classes} {...attrs}>
      <LaskuritHeading className="kds-m-2">{title}</LaskuritHeading>
      <p className="kds-m-2 kds-font-bold">{children}</p>
    </div>
  );
};

export default SimpleCard;
