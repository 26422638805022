import { Column, TextInput } from "kela-design-system";
import React, { ReactElement, useMemo } from "react";
import useId from "../hooks/useId";

type Primitive = string | number | null | undefined;

const decimalSeparator = (() => {
  const n = 1.1;
  const sep = n.toLocaleString().substring(1, 2);
  return sep;
})();

// Checks whether the rounded value is close-enough to the actual value.
const isAlmostInteger = (value: number) => {
  return Math.abs(Math.round(value) - value) < 0.00001;
};

const isCorrectStep = (step: string | number, value: number) => {
  // The actual value should be some integer times the step.
  // Due to floating point precisions, this is a bit of a hack.
  return isAlmostInteger(value / parseFloat(step + ""));
};

type TextInputProps = React.ComponentProps<typeof TextInput>;

type OmittedFields = "type" | "onChange" | "value" | "min" | "max" | "select" | "multiline" | "rows";

interface NumberInputProps extends Omit<TextInputProps, OmittedFields> {
  value: number | null;
  min?: Primitive;
  max?: Primitive;
  step?: number;
  maxValueLength?: number;
  onChange?: (value: number | null) => void;
  addonBefore?: JSX.Element;
  addonAfter?: JSX.Element;
}

const NumberInput = (props: NumberInputProps): ReactElement => {
  const {
    value,
    labelText,
    helpText,
    disabled,
    infoText,
    onChange,
    min,
    max,
    step = 1,
    id,
    inputWidth,
    maxValueLength = 16, // HARDCODED ARBITRARY MAX-LENGTH,
    ...attrs
  } = props;

  const validationRegex = useMemo(() => {
    const allowDecimal = !Number.isInteger(parseFloat(step + ""));
    return allowDecimal ? new RegExp(`[0-9]|\\${decimalSeparator}`) : /[0-9]/;
  }, [step]);

  const elementId = useId(id);

  const handleChange = (value: string) => {
    if (!onChange) return;
    if (value.length > maxValueLength) return;

    const num = parseFloat(value);
    if (isNaN(num)) {
      onChange(null);
      return;
    }
    if (isCorrectStep(step, num)) {
      onChange(num);
    }
  };

  return (
    <TextInput
      id={elementId}
      labelText={labelText}
      helpText={helpText}
      infoText={infoText}
      type={"number"}
      step={step}
      value={value !== null ? value : ""}
      min={min !== null ? min : ""}
      max={max !== null ? max : ""}
      onChange={(event) => handleChange(event.target.value)}
      onWheel={(e) => e.currentTarget.blur()}
      disabled={disabled}
      onKeyDown={(event) => {
        if (event.getModifierState("Control")) return;

        if (event.key.length === 1 && !validationRegex.test(event.key)) {
          event.preventDefault();
        }
      }}
      inputWidth={inputWidth || ((children) => <Column md={6}>{children}</Column>)}
      {...attrs}
    />
  );
};

export default NumberInput;
