import { useEffect } from "react";
import { TimerResult, TimerSettings, useTimer } from "react-timer-hook";

interface TimeoutSettings extends TimerSettings {
  onTreshold: () => void;
  tresholdSeconds: number;
}

/**
 * onTreshold: function ran when timer hits treshold,
 * tresholdSeconds: treshold used by timer in seconds
 */
function useTimeout(settings: TimeoutSettings): TimerResult {
  const { expiryTimestamp, onExpire, onTreshold, tresholdSeconds } = settings;

  const timer = useTimer({
    expiryTimestamp: expiryTimestamp,
    onExpire: onExpire,
  } as TimerSettings);

  const tresholdHit = timer.totalSeconds <= tresholdSeconds;

  useEffect(() => {
    tresholdHit && onTreshold();
  }, [tresholdHit]);

  return { ...timer };
}

export default useTimeout;
